import styled from 'styled-components'

import {device} from '../../styles/Devices'

export const Container = styled.div`
background-color: #EF6B2A;
height: 600px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
@media ${device.laptop} {
    padding: 20px;
    height: 300px;
    box-sizing: border-box;
}
`

export const Title = styled.p`
margin: 0;
font-size: 50px;
font-weight: 600;
text-align: center;
color: #262626;
@media ${device.laptop} {
font-size: 30px;
}
`

export const Description = styled.p`
margin: 0;
font-size: 25px;
text-align: center;
margin-top: 20px;
color: #262626;
font-weight: 200;
@media ${device.laptop} {
font-size: 15px;
}
`

export const ContainerServices = styled.div`
    padding: 75px 0;
`

export const TitleService = styled.p`
    text-align: center;
    color: #EF5D14;
    font-size: 40px;
    margin-bottom: 50px;
  font-weight: 600;
    @media ${device.laptop} {
      font-size: 25px;
    }
`

export const TopicsServices = styled.div`
margin: 80px 0;
    display: flex;
    justify-content: ${props => props.direction === 'right' ? 'flex-end' : 'flex-start'};
`

export const BoxService = styled.div`
    width: 70%;
    background-color: ${props => props.color};
    display: flex;
    padding: 50px;
    flex-direction: column;
    align-items: ${props => props.direction === 'right' ? 'flex-start' : 'flex-end' } ;
    justify-content: center;
    box-sizing: border-box;
    height: 200px;
    border-top-right-radius: ${props => props.direction === 'right' ? 0 :  50}px;
    border-bottom-right-radius: ${props => props.direction === 'right' ? 0 :  50}px;

    border-top-left-radius: ${props => props.direction !== 'right' ? 0 :  50}px;
    border-bottom-left-radius: ${props => props.direction !== 'right' ? 0 :  50}px;
    @media ${device.laptop} {
      width: 80%;
    }
`

export const BoxServiceTitle = styled.p`
 text-align: left;
 width: 50%;
 font-size: 35px;
 color: white;
 padding: 0;
 font-weight: 500;
 margin: 0;
 @media ${device.laptop} {
  width: 100%;
  font-size: 22px;
 }
`

export const BoxServiceDescription = styled.p`
 width: 50%;
 font-size: 20px;
 padding: 0;
 margin: 0;
 margin-top: 10px;
 font-weight: 200;
    color: white;
    @media ${device.laptop} {
  width: 100%;
  font-size: 13px;
 }
`