import styled from 'styled-components'

import { device } from '../../styles/Devices';

export const Container = styled.div`
    background-color: #2F1DB8;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media ${device.laptop} {
    padding: 20px;
    height: 300px;
    box-sizing: border-box;
  }
`

export const Title = styled.p`
  margin: 0;
  font-size: 50px;
  text-align: center;
  color: white;
  font-weight: 600;
  @media ${device.laptop} {
    font-size: 20px;
  }
`

export const Description = styled.p`
  margin: 0;
  font-size: 25px;
  text-align: center;
  margin-top: 20px;
  color: white;
  font-weight: 100;

  @media ${device.laptop} {
    font-size: 15px;
  }
`

export const ContainerProcess = styled.div`
  padding: 100px ${props => props.theme.spaces.page}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.laptop} {
    padding: 0 ${props => props.theme.spaces.pageMobile}px;
  }
`

export const MainTitle = styled.p`
  font-size: 50px;
  color: ${props => props.theme.colors.primary};
  font-weight: 600;
  @media ${device.laptop} {
    margin-top: 50px;
    font-size: 30px;
  }
`

export const ContainerBoxe = styled.div`
  margin-bottom: ${props => !props.margin ? 100 : 0}px;
  display: flex;
  width: 100%;
  justify-content: ${props => props.direction === 'right' ? 'flex-end' : 'flex-start'};

  @media ${device.laptop} {
    margin-bottom: ${props => !props.margin ? 40 : 0}px;
  }
`

export const Box = styled.div`
  width: 65%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${device.laptop} {
    width: 100%;
  }
`

export const ContainerCircle = styled.div`
  width: flex;
  margin-right: 20px;
`

export const Circle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Number = styled.p`
  margin: 0;
  font-size: 35px;
  padding: 0;
  color: white;
`

export const ContainerText = styled.div`
`

export const TitleProcess = styled.p`
  font-size: 25px;
  color: #262626 ;
  font-weight: 600;
  @media ${device.laptop} {
    font-size: 20px;
  }
`

export const TextProcess = styled.p`
  color: #262626;
  @media ${device.laptop} {
    font-size: 15px;
  }
`
