import styled from 'styled-components'

import backgroundBusinessMan from "../../assets/images/pessoasenergia.jpeg"


import { device } from '../../styles/Devices';


export const Background = styled.div`
width: 100% ;
height: 500px;
background-image: url(${backgroundBusinessMan});
background-repeat: no-repeat;
background-size: cover;
`

export const Container = styled.div`
  background: rgba(239, 93, 20, 0.6); 
  opacity: 1;
  width: 100% ;
  height: 500px;
  display: flex ;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;

@media ${device.laptop} {
  margin-top: 100px;
}

`

export const Title = styled.p`
  text-align: center;
  font-size: 70px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  margin-top: -20px;

  @media ${device.laptop} {
    font-size: 30px;
  }
`

export const Shape = styled.div`
  width: 700px;
  height: 100px;
  background: ${props => props.theme.colors.primary} ;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  position: relative;
  margin-top: -50px;
  margin-left: 30%;
  @media ${device.laptop} {
    width: 300px;
    height: 500px;
  }
`

export const Button = styled.button`
  width: 360px;
  height: 70px;
  border: none;
  font-size: 23px;
  color: ${props => props.theme.colors.light};
  background: ${props => props.theme.colors.button} 0% 0% no-repeat padding-box;
  border-radius: 34px;
  cursor: pointer ;
  box-sizing: border-box;

  &:hover{
    transform:scale(1.1,1.1);
    transition: all 200ms ease-in-out;
  }

  @media ${device.laptop} {
    font-size: 14px;
    width: 200px;
    height: 40px;
  }
`
export const Divisor = styled.div`
  width: 500px;
  height: 70px;
  position: absolute;
  margin-top: 500px;
  /* UI Properties */
  background: #039177 0% 0% no-repeat padding-box;
  border-radius: 0px 60px;
  opacity: 1;

  @media ${device.laptop} {
    width: 300px;
    height: 50px;
  }
`