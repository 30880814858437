// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/images/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n#root, body{\n  margin: 0;\n  padding: 0;\n}\n\n.App {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex:1 1;\n  margin: 0;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  flex-direction: column;\n  background-color: #2c2c2c;\n  padding: 12px;\n  box-sizing: border-box;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.App h1{\n  color: #f68349;\n  margin: 0;\n  font-size: 20px;\n  margin-bottom: 16px;\n}\n\n.App p{\n  margin: 0;\n  font-size: 14px;\n  /* color: rgb(206, 206, 206); */\n  color: #2c2c2c;\n}", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,QAAM;EACN,SAAS;EACT,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,sBAAsB;EACtB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,yDAAuD;AACzD;;AAEA;EACE,cAAc;EACd,SAAS;EACT,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,+BAA+B;EAC/B,cAAc;AAChB","sourcesContent":["\n#root, body{\n  margin: 0;\n  padding: 0;\n}\n\n.App {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex:1;\n  margin: 0;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  flex-direction: column;\n  background-color: #2c2c2c;\n  padding: 12px;\n  box-sizing: border-box;\n  background-image: url('./assets/images/background.png');\n}\n\n.App h1{\n  color: #f68349;\n  margin: 0;\n  font-size: 20px;\n  margin-bottom: 16px;\n}\n\n.App p{\n  margin: 0;\n  font-size: 14px;\n  /* color: rgb(206, 206, 206); */\n  color: #2c2c2c;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
