import React from 'react'

import { Button } from './WhatsAppButtonStyles'

const WhatsAppButton = () => {

  const openLink = () => {
    window.open('https://wa.me/5511911479161?text=Ol%C3%A1%20Parcon%20Energia%20Solar%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20seus%20produtos.', '_blank')
  }
  
  return (
    <Button onClick={() => openLink()}/>
  )
}

export default WhatsAppButton;