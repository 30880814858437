import React from 'react'

import { Container, Title, Description, Button } from './BannerStyles'

const Banner = ({callback}) => {
  return (
    <Container>
      <Title>Economia e <br></br> sustentabilidade<br></br> para o seu futuro!</Title>
      <Description>Chega de pagar caro na<br></br> sua conta de luz.</Description>
      <Button onClick={() => callback('aboutUs')} >Saiba mais</Button>
    </Container>
  )
}

export default Banner
