import styled from 'styled-components'

import { device } from '../../styles/Devices';

export const Container = styled.header`
  width: 100%;
  padding: 0 ${props => props.theme.spaces.page}px;
  box-sizing: border-box;
  height: auto;
  background-color: ${(props) => props.theme.colors.primary};
  position: fixed;
  opacity: 95%;
  z-index: 100;
  top:0;
  
  @media ${device.laptop} {
    padding: 16px 0px;
    display: none ;
  }
`

export const ListInformation = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 75px;

  @media ${device.laptop} {
    height: 130px;
    text-align: center;
    flex-direction: column;
  }
`

export const ItemInformation = styled.li`
  color: black;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 200;
  @media ${device.laptop} {
    font-size: 11px;
    margin: 2px 0;
    box-sizing: border-box;
  }
`

export const Text = styled.p`
  margin: 0 0 0 10px;
  height: 10px;
  font-size: 12px ;
`