import React, { useEffect, useState } from "react"

import Home from "./Home/Home"

import { Route, Routes, useNavigate, useLocation } from "react-router-dom"


import AboutUs from "./AboutUs/AboutUs";
import Header from "../components/Header/Header";
import Menu from "../components/Menu/Menu";
import { Background, Container, ContainerOthersPage } from "./Home/HomeStyles";
import Banner from "../components/Banner/Banner";
import Invest from "../components/Invest/Invest";
import Footer from "../components/Footer/Footer";
import WhatsAppButton from "../components/WhatsAppButton/WhatsAppButton";
import AboutEnergyPage from "./AboutEnergy/AboutEnergyPage";
import ProjectsPage from "./Projects/ProjectsPage";
import ContactPage from "./Contact/ContactPage";
import MenuMobile from '../components/MenuMobile/MenuMobile'

const MainPage = () => {

  const navigate = useNavigate()
  const location = useLocation()

  const [isHome, setIsHome] = useState(false)

  useEffect(() => {
    if(location.pathname === '/') setIsHome(true)
    else setIsHome(false)
  }, [location])


  const executeScroll = (nameAction) => {
    navigate(nameAction)
  }

  return (
    <>
      <Header />
      <MenuMobile />
      {isHome ? 
      <Background>
        <Container>
          <Menu callback={(nameMenu) => executeScroll(nameMenu)} />
          <Banner callback={(nameMenu) => console.log(nameMenu)} />
        </Container>
      </Background>
        :
        <ContainerOthersPage>
          <Menu callback={(nameMenu) => executeScroll(nameMenu)} />
        </ContainerOthersPage>
      }
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/aboutEnergy" element={<AboutEnergyPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      <Invest />
      <Footer />
      <WhatsAppButton />
    </>
  )
}

export default MainPage
