import React from 'react'
import { useNavigate } from "react-router-dom"

import { Container, CloseIcon, List, Item } from './OpenMenuStyles'

import ImgClose from '../../assets/icons/x.svg'

import './animation.css'

const OpenMenu = ({status, callback}) => {

    const navigate = useNavigate()


    const navigateTo = (name) => {
        navigate(name)
        callback()
    }

    return (
        status &&
        <Container className={'slideInRight'}>
            <CloseIcon onClick={() => callback()} src={ImgClose} />
            <List>
                <Item onClick={() => navigateTo('/')}>Página inicial</Item>
                <Item onClick={() => navigateTo('aboutEnergy')}>Como funciona a energia solar?</Item>
                <Item onClick={() => navigateTo('aboutUs')}>Sobre nós</Item>
                <Item onClick={() => navigateTo('projects')}>Projetos</Item>
                <Item onClick={() => navigateTo('contact')}>Contato</Item>
            </List>
        </Container>
        
    )
}

export default OpenMenu