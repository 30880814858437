import React from 'react'

import { Container, ListAdvantages, ItemAdvantage, Title, Description } from './AdvantagesStyles'

import MoneySvg from '../../assets/icons/material-attach-money.svg'
import ComputerSvg from '../../assets/icons/material-computer.svg'
import SunSvg from '../../assets/icons/ionic-ios-sunny.svg'
import EscudoSvg from '../../assets/icons/escudo.svg'

const Advantages = () => {
  return (
    <Container>
      <ListAdvantages>
        <ItemAdvantage>
          <img width={50} src={MoneySvg}/>
          <Title>Economia</Title>
          <Description>Percebida <br/> logo no 1º mês</Description>
        </ItemAdvantage>
        <ItemAdvantage>
          <img width={80}   src={ComputerSvg}/>
          <Title>Tecnologia</Title>
          <Description>O mais atual da <br/> tecnologia sustentável</Description>
        </ItemAdvantage>
        <ItemAdvantage>
          <img width={80}  src={SunSvg}/>
          <Title>Sustentável</Title>
          <Description>Energia limpa, <br/> renovável, <br/> e abundante</Description>
        </ItemAdvantage>
        <ItemAdvantage>
          <img width={80}  src={EscudoSvg}/>
          <Title>Durabilidade</Title>
          <Description>Garantia de produção <br/> de 25 anos</Description>
        </ItemAdvantage>
      </ListAdvantages>
    </Container>
  )
}

export default Advantages