import styled from 'styled-components'

import { device } from '../../styles/Devices';

export const Container = styled.div`
    padding: 0 ${props => props.theme.spaces.page}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

  @media ${device.laptop} {
    display: none ;
    flex-direction: column;
    padding: 16px ${props => props.theme.spaces.pageMobile}px;
  }
`

export const Logo = styled.img`
    width: 250px ;

    @media ${device.laptop} {
      width: 130px;
    }
`

export const ItemMenu = styled.p`
	margin:0;
	padding: 0;
  font-size: 12px;
  color: ${props => props.theme.colors.light};
  padding-bottom: 2px;
  cursor: pointer;
  
  &:after {
    content: '';
    display: block;
    margin-top: 2px;
    width: 20px;
    border-top: 2px solid ${props => props.theme.colors.button};
  }
  &:hover:after{
    content: '';
    width: 100%;
    -webkit-transition: width 0.2s ease-in-out;
    -moz-transition: width 0.2s ease-in-out;
    -o-transition: width 0.2s ease-in-out;
    transition: width 0.2s ease-in-out;
  }

  @media ${device.laptop} {
    
  }
`

export const ContainerItems = styled.div`
    display: flex ;
    flex-direction: row;
    gap: 25px;
    align-items: center;

    @media ${device.laptop} {
      flex-direction: column;
  }
   
`

export const Button = styled.button`
    box-shadow: 3px 3px 5px #00000042;
    border: 3px solid #02A895;
    border-radius: 34px;
    font: normal normal normal 26px/31px Museo 700;
    letter-spacing: 0px;
    color: ${props => props.theme.colors.light};
    font-size: 20px;
    background: transparent ;
    width: 280px;
    font-weight: 400;
    height: 60px;
    cursor: pointer;
    margin-left: 50px;

    &:hover{
      background: #02AD99 ;
      transform:scale(1.1,1.1);
      transition: all 200ms ease-in-out;
    }

    @media ${device.laptop} {
      margin-left: 0px;
      width: 250px;
      height: 50px;
      margin-bottom: 15px;
  }
`