import styled from 'styled-components'

import { device } from '../../styles/Devices';

export const Container = styled.div`
  padding: 0 ${props => props.theme.spaces.page}px;
  @media ${device.laptop} {
    padding: 0 ${props => props.theme.spaces.pageMobile}px;
    text-align: center;
    height: 100% ;
    display: flex ;
    align-items: center ;
    justify-content: center ;
    flex-direction: column;
  }
`

export const Title = styled.h1`
  font-size: 70px;
  margin: 0;
  color: ${props => props.theme.colors.light};
  font-weight: bold;
  margin-bottom: 16px;
  @media ${device.laptop} {
    font-size: 30px;
  }

`
export const Description = styled.p`
  font-size: 40px;
  margin: 0;
  font-weight: 200;
  margin-bottom: 16px;
  color: ${props => props.theme.colors.light};

  @media ${device.laptop} {
    font-size: 16px;
  }
`

export const Button = styled.button`
  width: 260px;
  height: 60px;
  border: none;
  font-size: 23px;
  color: ${props => props.theme.colors.light};
  background: ${props => props.theme.colors.button} 0% 0% no-repeat padding-box;
  border-radius: 34px;
  cursor: pointer ;

  &:hover{
    transform:scale(1.1,1.1);
    transition: all 200ms ease-in-out;
  }

  @media ${device.laptop} {
    width: 200px;
    height: 50px;
    font-size: 16px;
  }
`