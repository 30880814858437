import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"

import OpenMenu from './OpenMenu'

import { Container, Logo, Menu } from './MenuMobileStyles'

import LogoImg from '../../assets/icons/logowhite.svg'
import MenuImg from '../../assets/icons/menuicon.svg'

const MenuMobile = () => {

    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false)

    const statusMenu = () => {
        setIsOpen(!isOpen)
    }

    return (
        <Container>
            <Logo onClick={() => navigate('/')} src={LogoImg} />
            <Menu onClick={() => statusMenu()} src={MenuImg} />
            <OpenMenu status={isOpen} callback={() => statusMenu()} />
        </Container>
    )

}

export default MenuMobile