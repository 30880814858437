
import styled from 'styled-components'

export const Container = styled.div`
    position: absolute ;
    width:  237px ;
    height: 310px ;
    background: rgba(51, 51, 51, 0.93);
    right: 0 ;
    padding: 47px 30px ;
    box-sizing: border-box ;
    border-radius: 0px 0px 0px 60px;
    top: 0 ;
`

export const CloseIcon = styled.img`
    width: 18px ;
`

export const List = styled.ul`
  list-style-type: none;
    margin: 0 ;
    padding: 0 ;
    margin-top: 33px ;
`

export const Item = styled.li`
color: #FFFFFF;
font-weight: 300 ;
margin-bottom: 14px;
font-size:13px ;
cursor: pointer ;
&&:hover{
    color: ${props => props.theme.colors.primary} ;
}
`