import React from 'react'

import { Container, Title, Description, ContainerContact, ContainerDiver, ContainerTextDivier } from './ContactPageStyles'

import {Title as TitleFooter, Box, ContainerBox, Text } from '../../components/Footer/FooterStyles'

import { FaMapPin, FaRegEnvelope, FaPhone } from "react-icons/fa"


const ContactPage = () => {
  return (
    <>
      <Container>
        <Title>Entre em contato <br/> com a Parcon Solar!</Title>
        <Description>Se interessou pelos nossos trabalhos <br/> ou quer conversar conosco? Entre em <br/> contato agora mesmo!</Description>
      </Container>
      <ContainerContact>
        <ContainerDiver>
          <ContainerTextDivier>
            <TitleFooter>Contato:</TitleFooter>
            <Box>
              <FaMapPin color={'#202020'} size={20} />
              <Text>Alameda dos Maracatins, 780, Cj 408, Moema - São Paulo/SP</Text>
            </Box>
            <Box>
              <FaRegEnvelope color={'#202020'} size={20} />
              <ContainerBox>
                <Text>contato@parconsolar.com.br</Text>
              </ContainerBox>
            </Box>
            <Box>
              <FaPhone color={'#202020'} size={20} />
              <Text>(11) 91147-9161</Text>
            </Box>
          </ContainerTextDivier>
        </ContainerDiver>
      </ContainerContact>
    </>
  )
}

export default ContactPage