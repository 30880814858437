import React, { forwardRef } from 'react'

import { Container, Logo, FooterC, SocialMedia, ContainerText, Title, Box, ContainerBox, Text, Instagram, Facebook, Triangle, ContainerTriangle } from './FooterStyles'

import { FaMapPin, FaRegEnvelope, FaPhone } from "react-icons/fa"

import LogoParcon from '../../assets/images/parcon1.png'


const Footer = (props, ref) => {

  const openFacebook = () => {
    window.open('https://www.facebook.com/Parcon-Solar-107372905378189', '_blank')
  }

  const openInstagram = () => {
    window.open('https://www.instagram.com/parconsolar/', '_blank')
  }

  return (
    <Container ref={ref}>
      <Logo src={LogoParcon} />
      <FooterC>
      <ContainerTriangle>
        <Triangle />
      </ContainerTriangle>
        <SocialMedia>
          <Instagram onClick={() => openInstagram()} />
          <Facebook onClick={() => openFacebook()} />
        </SocialMedia>
        <ContainerText>
          <Title>Contato:</Title>
          <Box>
            <FaMapPin color={'#202020'} size={20} />
            <Text>Alameda dos Maracatins, 780, Cj 408, Moema - São Paulo/SP</Text>
          </Box>
          <Box>
            <FaRegEnvelope color={'#202020'} size={20} />
            <ContainerBox>
              <Text>contato@parconsolar.com.br</Text>
            </ContainerBox>
          </Box>
          <Box>
            <FaPhone color={'#202020'} size={20} />
            <Text>(11) 91147-9161</Text>
          </Box>
        </ContainerText>
      </FooterC>
    </Container>
  )
}

export default forwardRef(Footer);