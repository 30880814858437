import React from 'react'

import EyesSvg from '../../assets/icons/bullseye-solid.svg'
import EyesSolidSvg from '../../assets/icons/eye-solid.svg'
import HandsSvg from  '../../assets/icons/hands-holding-solid.svg'

import { Container, Title, Description, ContainerTeam, TeamText, TeamTitle, BoxTeam, NameTeam, DescriptionTeam, ContainerTopics, Banner, TextBanner, DescriptionBanner, ContainerUls, Ul, List, Bullet, ListText, IconImg } from './AboutUsStyles'

const AboutUs = () => {
  return (
    <>
      <Container>
        <Title>Sobre a Parcon Solar</Title>
        <Description>Conheça nossos colaboradores <br/> e um pouco da nossa história.</Description>
      </Container>
      <ContainerTeam>
        <TeamText>Nossa empresa conta com três fundadores que possuem <span style={{color: '#EF5D14'}}>grande experiência</span> no mundo do empreendedorismo e no ramo de energia solar.</TeamText>
        <TeamTitle>Conheça eles:</TeamTitle>
        <BoxTeam>
          <NameTeam color={'#2F1DB8'}>Gislene <br /> Bergh</NameTeam>
          <DescriptionTeam>Empresária há 16 anos, com mais de 9 anos de experiência no ramo de energia fotovoltaica, pioneira nessa área e líder de mercado no interior de São Paulo;</DescriptionTeam>
        </BoxTeam>
        <BoxTeam>
          <NameTeam color={'#EF5D14'}>Marcelo<br />Kikuchi</NameTeam>
          <DescriptionTeam>Empresário/empreendedor com grande personalidade comercial;</DescriptionTeam>
        </BoxTeam>
        <BoxTeam>
          <NameTeam color={'#039177'}>André <br /> Conde</NameTeam>
          <DescriptionTeam>Empresário/consultor atuarial de grandes empresas.</DescriptionTeam>
        </BoxTeam>
      </ContainerTeam>
      <ContainerTopics>
        <Banner color='#2F1DB8'>
          <TextBanner>Missão</TextBanner>
          <IconImg width={120} src={EyesSvg} />
        </Banner>
        <DescriptionBanner>Levar economia e independência energética, através de uma energia limpa e sustentável, buscando ser referência em desenvolvimento de projetos de energia limpa e com excelência para nossos clientes.</DescriptionBanner>


        <Banner color='#EF5D14'>
          <TextBanner>Visão</TextBanner>
          <IconImg width={120} src={EyesSolidSvg} />
        </Banner>
        <DescriptionBanner>Ser referência nacional em soluções inovadoras de geração de energia solar sustentável e sistemas confiáveis que proporcionem economia, conforto e independência energética aos nossos clientes.</DescriptionBanner>


        <Banner color='#039177'>
          <TextBanner>Valores</TextBanner>
          <IconImg width={120} src={HandsSvg} />
        </Banner>
        {/* <DescriptionBanner>Levar economia e independência energética, através de uma energia limpa e sustentável, buscando ser referência em desenvolvimento de projetos de energia limpa e com excelência para nossos clientes.</DescriptionBanner> */}


        <ContainerUls>
          <Ul>
            <List><Bullet color={'#039177'} /> <ListText>Excelência no atendimento;</ListText></List>
            <List><Bullet color={'#2F1DB8'} /> <ListText>Ética nas relações;</ListText></List>
            <List><Bullet color={'#EF5D14'}  /> <ListText>Qualidade de vida, respeito aos colaboradores, parceiros, clientes e meio ambiente;</ListText></List>
            <List><Bullet color={'#039177'} /> <ListText>Credibilidade e transparência;</ListText></List>
          </Ul>
          <Ul>
            <List><Bullet color={'#2F1DB8'}  /> <ListText>Sustentabilidade;</ListText></List>
            <List><Bullet color={'#EF5D14'}/> <ListText>Tecnologia e inovação;</ListText></List>
            <List><Bullet color={'#039177'} /> <ListText>Orientação para resultados;</ListText></List>
            <List><Bullet color={'#2F1DB8'} /> <ListText>Eficiência e independência energética.</ListText></List>
          </Ul>

        </ContainerUls>


      </ContainerTopics>
    </>
  )
}

export default AboutUs