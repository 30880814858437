import styled from 'styled-components'

import backgroundBusinessMan from "../../assets/images/image5.jpeg"

import { device } from '../../styles/Devices';

export const Background = styled.div`
  width: 100% ;
  height: 700px;
  background-image: url(${backgroundBusinessMan});
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
`

export const Container = styled.div`
  background: ${props => props.theme.colors.button}; 
  opacity: 0.6;
  width: 100% ;
  height: 700px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;

`

export const Shape = styled.div`
  width: 500px;
  height: 70px;
  background: ${props => props.theme.colors.primary} ;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  position: absolute;
  margin-bottom: -35px;
  @media ${device.laptop} {
    width: 300px;
    height: 50px;
    margin-bottom: -25px;
  }
`

export const Main = styled.div `
  background: #EFEFEF ;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: auto;
`