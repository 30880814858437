import styled from 'styled-components'
import { device } from '../../styles/Devices'

export const Container = styled.div`
   display: none ;
    @media ${device.laptop} {
        width: 100% ;
        z-index: 200 ;
        height: 75px ;
        top:0 ;
        position: fixed ;
        display: flex ;
        padding: 20px 37px ;
        box-sizing: border-box;
        align-items: center ;
        justify-content: space-between ;
        background-color: ${props => props.theme.colors.primary} ;
    }
`

export const Logo = styled.img`
    width: 142px;
`

export const Menu = styled.img`
    width: 30px ;
    cursor: pointer;
`