import React from "react"
import { BrowserRouter } from "react-router-dom"

import MainPage from "./pages/MainPage"

import { ThemeProvider } from "styled-components"
import GlobalStyle from './styles/GlobalStyle';

import { theme } from "./styles/Themes"

import './App.css'


function App() {
  
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <MainPage />
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
