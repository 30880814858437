import React, { forwardRef } from 'react'

import { Container, Title, Box, BoxImage, Image, ChevronLeft, ChevronRight } from './ProjectsStyles'

const Projects = (props, ref) => {

  const [count, setCount] = React.useState(1)

  const nextImage = () => {
    if(count >= 1 && count < 9){
      let newPage = (count + 1)
      console.log('nextImage', newPage)
      setCount(newPage)
    }
  }

  const backImage = () => {
    if(count > 1){
      const newPage = count - 1
      setCount(newPage)
    }
  }

  return (
    <Container ref={ref}>
      <Title>Nossos projetos <br /> concluídos</Title>
        <BoxImage>
          <ChevronLeft disable={count <= 1 && true} onClick={() => backImage()} color='white' size={100} />
          <Image src={require(`../../assets/images/image${count}.jpeg`)} />
          <ChevronRight disable={count === 9 && true}  onClick={() => nextImage()} color='white' size={100} />
        </BoxImage>
    </Container>
  )
}

export default forwardRef(Projects)