import React, {forwardRef} from 'react'
import { Button } from '../Banner/BannerStyles';
import { Container, ContainerShapes, ShapeOne, ShapeTwo, ContainerInformation, Title, Description } from './AboutStyles';

const About = (props, ref) => {

  const openLink = () => {
    window.open('https://wa.me/5511911479161?text=Ol%C3%A1%20Parcon%20Energia%20Solar%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20seus%20produtos.', '_blank')
  }

  return (
    <Container ref={ref}>
      <ContainerShapes>
        <ShapeOne/>
        <ShapeTwo />
      </ContainerShapes>
      <ContainerInformation>
        <Title>Há mais de 10 anos solucionando de forma inovadora e sustentável o mercado de energia solar.</Title>
        <Description>
        A Parcon Solar nasce com uma competência e o pioneirismo de mais de 10 anos experiência no mercado de energia solar e com a expertise de grandes instalações já realizadas.
        </Description>
        <Description bottom={true}>
        Com isso, oferecemos as melhores soluções em energia limpa, renovável e sustentável. No Brasil, já são mais de 1.000 projetos instalados de geração de energia sustentável. 
        </Description>
        <Button onClick={() => openLink()}>Saiba Mais</Button>
      </ContainerInformation>
    </Container>
  )
}

export default forwardRef(About);