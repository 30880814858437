import React, { forwardRef } from 'react'

import { Background, Container, Button, Title, Divisor } from './InvestStyles'

const Invest = (props, ref) => {

  const openLink = () => {
    window.open('https://wa.me/5511911479161?text=Ol%C3%A1%20Parcon%20Energia%20Solar%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20seus%20produtos.', '_blank')
  }


  return (
    <React.Fragment>
      <Background ref={ref}>
        <Container>
          <Title>Invista na economia com <br/> a Parcon Solar!</Title>
          <Button onClick={() => openLink()}>Faça seu orçamento</Button>
          <Divisor/>
        </Container>
      </Background>
    </React.Fragment>
  )
}

export default forwardRef(Invest);