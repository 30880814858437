import React from 'react'

import { Container, Logo, ItemMenu, ContainerItems, Button } from './MenuStyles'
import { useNavigate } from "react-router-dom"

const logoParcon = require('../../assets/images/parcon1.png') 


const Menu = ({callback}) => {

  const navigate = useNavigate()

  return (
    <Container>
      <Logo onClick={() => navigate("/")} src={logoParcon} />
      <ContainerItems>
        <ItemMenu onClick={() => callback('aboutEnergy')} >Como funciona a energia solar?</ItemMenu>
        <ItemMenu onClick={() => callback('aboutUs')}>Sobre nós</ItemMenu>
        <ItemMenu onClick={() => callback('projects')}>Projetos</ItemMenu>
        <ItemMenu onClick={() => callback('contact')}>Contato</ItemMenu>
        <Button onClick={() => callback('simulate')}>Faça seu orçamento</Button>
      </ContainerItems>
    </Container>
  )
}

export default Menu;