import styled from 'styled-components'

import { device } from '../../styles/Devices';


export const Container = styled.div`
  padding: 200px ${props => props.theme.spaces.page}px ;
  box-sizing: border-box;

  @media ${device.laptop}{
    padding: ${props => props.theme.spaces.pageMobile}px;
  }
`

export const ListAdvantages = styled.div`
  display: flex ;
  flex-direction: row;
  justify-content: space-between;
  @media ${device.laptop}{
    flex-direction: column;
  }
`
export const ItemAdvantage = styled.div`
  text-align: center;
  display: flex ;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  @media ${device.laptop}{
    margin: 20px 0;
  }
`

export const Title = styled.p`
  font-size: 30px;
  margin-top: 32px;
  font-weight: bold;
  margin: 0 ;
  margin-bottom: 16px;

  @media ${device.laptop}{
    font-size: 20px;
  margin-bottom: 8px;
  }
`

export const Description = styled.p`
  font-size: 15px;
  margin: 0;
  @media ${device.laptop}{
    font-size: 12px;
  }
`