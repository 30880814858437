
import styled from 'styled-components'

import {device} from '../../styles/Devices'

export const Container = styled.div`
background-color: #039177;
height: 600px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
@media ${device.laptop} {
    padding: 20px;
    height: 300px;
    box-sizing: border-box;
}
`

export const Title = styled.p`
margin: 0;
font-size: 50px;
font-weight: 600;
text-align: center;
color: #262626;
@media ${device.laptop} {
font-size: 30px;
}
`

export const Description = styled.p`
margin: 0;
font-size: 25px;
text-align: center;
margin-top: 20px;
color: #262626;
font-weight: 200;
@media ${device.laptop} {
font-size: 15px;
}
`

export const ContainerTeam = styled.div`
  padding: 75px ${props => props.theme.spaces.page}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.laptop} {
    padding: 0 ${props => props.theme.spaces.pageMobile}px;
  }
`

export const TeamText = styled.p`
  font-size: 45px;
  text-align: left;
  font-weight: 500;
  @media ${device.laptop} {
    font-size: 20px;
    margin-top: 50px;
   
    text-align: center;
  }
`


export const TeamTitle = styled.p`
  color: #EF5D14;
  font-size: 45px;
  text-align: left;
  width: 100%;
  @media ${device.laptop} {
    font-size: 20px;
    text-align: center;
  }
`


export const BoxTeam = styled.div`
  display: flex;
  width: 100%;
  margin: 10px;
  flex-direction: row;
  min-height: 200px;
  justify-content: flex-start;
  @media ${device.laptop} {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    width: 50%;
  }
`

export const NameTeam = styled.p`
  text-align: center;
  color: ${props => props.color};
  display: flex;
  font-size: 50px;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 200px;
  min-height: 100%;
  margin: 0;
  font-weight: 600;
  @media ${device.laptop} {
    font-size: 30px;
    margin: 0;
    min-width: auto;
  }
`

export const DescriptionTeam = styled.p`
  font-size: 20px;
  width: auto;
  margin: 0;
  font-weight: 200;
  padding: 15px;
  @media ${device.laptop} {
    font-size: 15px;
    text-align: center;
  }

`

export const ContainerTopics = styled.div`
  padding: 0 ${props => props.theme.spaces.page}px;

  @media ${device.laptop} {
    padding: 0 ${props => props.theme.spaces.pageMobile}px;
  }
`

export const Banner = styled.div`
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  background-color: ${props => props.color};
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  justify-content: space-between;
  box-sizing: border-box;

  @media ${device.laptop} {
    padding: 20px;
    height: 75px;
  }
`

export const TextBanner = styled.p`
  font-size: 35px;
  margin: 0;
  color: white;

  @media ${device.laptop} {
    font-size: 20px;
    margin-left: 15px;
  }
`

export const DescriptionBanner = styled.p`
  
  width: 60%;
  padding: 0 40px;
  margin-top: 50px;
  margin-bottom: 70px;
  box-sizing: border-box;

  @media ${device.laptop} {
    width: 100%;
  }
`

export const ContainerUls = styled.div`
  display: flex;
  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 30px;
  }
  `

export const Ul = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  margin-top: 50px;
  @media ${device.laptop} {
    width: 100%;
    margin-top: 0;
  }
`

export const List = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`

export const ListText = styled.p`
  margin: 0;
  
`

export const Bullet = styled.div`
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: 10px;

`

export const IconImg = styled.img`
  width: 120px;
  @media ${device.laptop} {
    width: 50px;
  }

` 