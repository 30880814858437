import styled from 'styled-components'

import { device } from '../../styles/Devices';

export const Container = styled.div`
  width: 100% ;
  display: flex ;
  flex-direction: row;
  padding: ${props => props.theme.spaces.page}px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  @media ${device.laptop} {
    padding: ${props => props.theme.spaces.pageMobile}px;
    flex-direction: column;
    text-align: center;
  }
`

export const ContainerShapes = styled.div`
display: flex ;
 flex: 1;
  align-items: center;
  justify-content: flex-start;
`

export const ShapeOne = styled.div`
margin-top: -50px;
  width: 250px;
  height: 300px;
  background: ${props => props.theme.colors.button} ;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;

  @media ${device.laptop} {
    margin: 0 ;
    width: 150px;
  height: 200px;
  }
`

export const ShapeTwo = styled.div`
   width: 250px;
  height: 300px;
  background: ${props => props.theme.colors.background} ;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  position: absolute;
  margin: 50px 0 0 100px;

  @media ${device.laptop} {
    margin: 60px 0 0 60px;
    width: 150px;
  height: 200px;
  }
`

export const ContainerInformation = styled.div`
display: flex ;
flex:1;
flex-direction: column;

@media ${device.laptop} {
  margin-top: 50px;
  align-items: center;
}

`

export const Title = styled.p`
  margin: 0 ;
  color: ${props => props.theme.colors.secondary};
  font-size: 40px;
  font-weight: bold;
  @media ${device.laptop} {
    font-size: 20px;
    padding-top: 40px;
  }
`

export const Description = styled.p`
  font-size: 20px;
  margin: 0;
  font-weight: 200;
  margin-top: 16px;
  margin-bottom: ${props => props.bottom ? 20 : 0}px;
  @media ${device.laptop} {
    font-size: 12px;
  }
`