import styled, { keyframes }  from 'styled-components'

import WhatsAppImage from '../../assets/images/whatsapp.png'

import { device } from '../../styles/Devices';

const bounce = keyframes`
	100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
`

export const Button = styled.button`
  background: transparent url(${WhatsAppImage}) center center no-repeat;
  width: 60px;
  height: 60px;
  border: none;
  position: fixed;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  margin: 36px;
  cursor: pointer;
  animation-name: ${bounce};
  animation-duration: 2s;
  animation-iteration-count: infinite;

  @media ${device.laptop} {
    margin: 16px;
    width: 60px;
    height: 60px;
  }
`

