import React from 'react'

import {
    Container,
    ListInformation,
    ItemInformation,
    Text
  } from "./HeaderStyles"
  
import { FaMapMarkerAlt, FaPhone, FaRegEnvelope } from "react-icons/fa"


const Header = () => {
  return (
    <Container>
      <ListInformation>
        <ItemInformation>
          <FaMapMarkerAlt size={20} />
          <Text>Alameda dos Maracatins, 780, Cj 408 Moema - São Paulo/SP </Text>
        </ItemInformation>
        <ItemInformation>
          <FaRegEnvelope size={20} />
          <Text>contato@parconsolar.com.br</Text>
        </ItemInformation>
        <ItemInformation>
          <FaPhone size={20} />
          <Text>(11) 91147-9161</Text>
        </ItemInformation>
      </ListInformation>
  </Container>
  )
}

export default Header;