import React from 'react'
import Projects from '../../components/Projects/Projects'

import { Container, Title, Description, ContainerServices, TitleService, TopicsServices, BoxService, BoxServiceTitle, BoxServiceDescription } from './ProjectsPageStyles'


const ProjectsPage = () => {
  return (
    <>
     <Container>
        <Title>Nossos serviços</Title>
        <Description>Conheça nossos serviços e projetos que já foram concluídos.</Description>
      </Container>
      <ContainerServices>
        <TitleService>Nossos serviços</TitleService>
        <TopicsServices direction={'right'}>
          <BoxService direction={'right'} color={'#039177'}>
            <BoxServiceTitle>Comercial</BoxServiceTitle>
            <BoxServiceDescription>A energia solar é uma excelente alternativa para as empresas que buscam diminuir suas despesas de energia e ter rápido retorno sobre seu investimento.</BoxServiceDescription>
          </BoxService>
        </TopicsServices>
        <TopicsServices>
          <BoxService color={'#2F1DB8'}>
            <BoxServiceTitle>Residencial</BoxServiceTitle>
            <BoxServiceDescription>Seu imóvel mais valorizado, sua conta mais barata e um mundo mais limpo.</BoxServiceDescription>
          </BoxService>
        </TopicsServices>
        <TopicsServices direction={'right'}>
          <BoxService direction={'right'} color={'#EF5D14'}>
            <BoxServiceTitle>Industrial</BoxServiceTitle>
            <BoxServiceDescription>Sua indústria economizando</BoxServiceDescription>
          </BoxService>
        </TopicsServices>
      </ContainerServices>
      <Projects/>
    </>
   
  )
}

export default ProjectsPage