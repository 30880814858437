import React, { forwardRef } from 'react'

import { Container, ContainerShapes, ShapeOne, ShapeTwo, ContainerInformation, Title, Description } from './AboutEnergyStyles';

const AboutEnergy = (props, ref) => {
  return (
    <Container ref={ref}>
      <ContainerInformation>
        <Title>Já pensou em produzir sua própria energia elétrica?</Title>
        <Description>
        Isso é uma realidade viável e bastante atrativa, inclusive devido aos constantes aumentos nas contas de energia. </Description>
        <Description>
        Residências, empresas e outros estabelecimentos, vêm buscando soluções para a redução dos gastos com a energia elétrica. Dessa forma, o sol, sendo uma fonte renovável, abundante e limpa, pode ser usado ao nosso favor.  
        </Description>
        <Description>
        Essa solução contribui significativamente para a sustentabilidade do nosso planeta, uma vez que a energia solar é uma fonte que tem contribuído para a redução de emissão de gases de efeito estufa.
        </Description>
      </ContainerInformation>
      <ContainerShapes>
        <ShapeOne/>
        <ShapeTwo />
      </ContainerShapes>
    </Container>
  )
}

export default forwardRef(AboutEnergy)