import styled from 'styled-components'

import {device} from '../../styles/Devices'

export const Container = styled.div`
background-color: #EFEFEF;
height: 600px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
@media ${device.laptop} {
    padding: 20px;
    height: 300px;
    box-sizing: border-box;
}
`

export const Title = styled.p`
margin: 0;
font-size: 50px;
text-align: center;
font-weight: 600;
color: #404040;
@media ${device.laptop} {
font-size: 30px;
}
`

export const Description = styled.p`
margin: 0;
font-size: 25px;
text-align: center;
margin-top: 20px;
color: #404040;
font-weight: 200;

@media ${device.laptop} {
font-size: 15px;
}
`

export const ContainerContact = styled.div`
    width: 100%;
    display: flex; 
    justify-content: flex-end;
    margin-top: -100px;
    @media ${device.laptop} {
        margin-top: -40px;
    }
`

export const ContainerDiver = styled.div`
    width: 50%;
    background-color: #039177;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 50px;
    box-sizing: border-box;

    @media ${device.laptop} {
        width: 90%;
        padding: 30px;
    }
`

export const ContainerTextDivier = styled.div`
  margin: 0;

  @media ${device.laptop} {
    padding: 0 32px;
  }
`