export const theme = {
  colors: {
    primary: "#EF5D14",
    secondary: "#262626",
    light: "#FFFFFF",
    button: "#2F1DB8",
    background: "#039177",
  },
  spaces: {
    page: 250,
    pageMobile: 16
  }
}
