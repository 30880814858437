import styled from 'styled-components'

import { device } from '../../styles/Devices';

import { FaInstagram, FaFacebook } from "react-icons/fa"


const {innerWidth} = window;

const width = innerWidth/2 + 'px'

export const Container = styled.div`
  width: 100% ;
  display: flex ;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`

export const Logo = styled.img`
  width: 500px;
  padding: 40px 0;

  @media ${device.laptop} {
    width: 200px;
  }
`

export const FooterC = styled.footer`
  width: 100%;
  background: ${props => props.theme.colors.primary} ;
  box-sizing: border-box;
  padding: 32px 0;
`

export const SocialMedia = styled.div`
  display: flex ;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding:40px 0;
  background: ${props => props.theme.colors.primary} ;
`

export const ContainerText = styled.div`
  padding: 0 ${props => props.theme.spaces.page}px;
  margin: 0;

  @media ${device.laptop} {
    padding: 0 32px;
  }
`

export const Title = styled.p`
  font-size: 40px;
  color: ${props => props.theme.colors.light};
  margin: 0;

  @media ${device.laptop} {
    font-size: 20px;
  }
`

export const Box = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: row;
`

export const ContainerBox = styled.div``

export const Text = styled.p`
  margin: 0;
  margin-left: 16px;
  color: ${props => props.theme.colors.light} ;
  font-weight: 100;
  @media ${device.laptop} {
    font-size: 14px;
  }
  
`

export const Instagram = styled(FaInstagram)`
  font-size: 100px;
  color: '#202020';
  cursor: pointer ;

  @media ${device.laptop} {
    font-size: 50px;
  }
`
export const Facebook = styled(FaFacebook)`
  font-size: 100px;
  color: '#202020';
  cursor: pointer ;
  @media ${device.laptop} {
    font-size: 50px;
  }
`

export const Triangle = styled.div`
  width: 100%;
  border-right: ${width}  solid ${props => props.theme.colors.primary};
  border-top: 60px solid transparent;
  border-left: ${width} solid ${props => props.theme.colors.primary};
  
`

export const ContainerTriangle = styled.div`
  display: flex ;
  flex-direction: row;
  margin-top: -100px;
`
