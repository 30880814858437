import styled from "styled-components"

import backgroundBusinessMan from "../../assets/images/background-man.jpg"

import { device } from '../../styles/Devices';

const {innerWidth} = window;

const width = innerWidth/2 + 'px'


export const ContainerMain = styled.div`
  width: 100%;
  height: 100%;
  background: '#EFEFEF';
`

export const Background = styled.div`
  width: 100%;
  height: 900px;
  background-image: url(${backgroundBusinessMan});
  background-size: cover;

  @media ${device.laptop} {
    height: 650px;
  }
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  @media ${device.laptop} {
    /* margin-top: 160px; */
  }
`

export const ContainerOthersPage = styled.div`
  width: 100%;
  margin-top: 75px;
  background-color: #2B2B2B;
  @media ${device.laptop} {
    /* margin-top: 160px; */
  }
`

export const DiscoveryDivisor = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.primary} ;
  display: flex ;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
 
`

export const DiscoveryText = styled.p`
  font-size: 50px;
  font-weight: bold;
  @media ${device.laptop} {
    font-size: 25px;
  }
`

export const Triangle = styled.div`
  width: 100%;
  border-right: ${width}  solid ${props => props.theme.colors.primary};
  border-top: 100px solid transparent;
  border-left: ${width} solid ${props => props.theme.colors.primary};
  
`

export const ContainerTriangle = styled.div`
  display: flex ;
  flex-direction: row;
  margin-top: -100px;
`

