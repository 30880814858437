import React from "react"

import { Background, Container, Shape, Main } from "./BannerPhotoStyles"

const BannerPhoto = () => {
  return (
    <Main>
      <Background>
        <Container/>
      </Background>
      <Shape />
    </Main>
  )
}

export default BannerPhoto
