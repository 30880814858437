import React from 'react'

import { Container, Title, Description, ContainerProcess, MainTitle, Box, ContainerCircle, Circle, Number, ContainerText, TitleProcess, TextProcess, ContainerBoxe } from './AboutEnergyPageStyles'

const AboutEnergyPage = () => {
  return (
    <>
      <Container>
        <Title>Como funciona o sistema <br /> de produção de energia solar?</Title>
        <Description>Saiba como funciona esse sistema.</Description>
      </Container>
      <ContainerProcess>
        <MainTitle>O processo</MainTitle>

        <ContainerBoxe direction={'left'}>
          <Box>
            <ContainerCircle>
              <Circle color={'#02AD99'} >
                <Number>1</Number>
              </Circle>
            </ContainerCircle>
            <ContainerText>
              <TitleProcess>Painéis fotovoltaicos</TitleProcess>
              <TextProcess>Após a instalação dos painéis solares em seu telhado, captamos os raios solares através das células fotovoltaicas e os transformamos em energia elétrica de corrente contínua.</TextProcess>
            </ContainerText>
          </Box>
        </ContainerBoxe>

        <ContainerBoxe direction={'right'}>
          <Box>
            <ContainerCircle>
              <Circle color={'#2356CA'} >
                <Number>2</Number>
              </Circle>
            </ContainerCircle>
            <ContainerText>
              <TitleProcess>Inversor</TitleProcess>
              <TextProcess>Em seguida, essa energia é transformada pelo inversor em corrente alternada, que é a utilizada pelo consumidor final.</TextProcess>
            </ContainerText>
          </Box>
        </ContainerBoxe>

        <ContainerBoxe direction={'left'}>
          <Box>
            <ContainerCircle>
              <Circle color={'#F06C2B'} >
                <Number>3</Number>
              </Circle>
            </ContainerCircle>
            <ContainerText>
              <TitleProcess>Quadro de distribuição</TitleProcess>
              <TextProcess>Após a instalação do Kit de Energia Fotovoltaico, a concessionária substituirá o relógio de energia por um bidirecional, que registra tanto o seu consumo, quanto a energia excedente que o seu sistema solar injeta de volta à rede.</TextProcess>
            </ContainerText>
          </Box>
        </ContainerBoxe>

        <ContainerBoxe direction={'right'}>
          <Box>
            <ContainerCircle>
              <Circle color={'#02AD99'} >
                <Number>4</Number>
              </Circle>
            </ContainerCircle>
            <ContainerText>
              <TitleProcess>Eletrodomésticos/ Iluminação</TitleProcess>
              <TextProcess>Assim, após a instalação desse kit de Energia Solar, comunicamos a concessionária local que sua residência ou sua empresa é uma geradora de energia renovável.</TextProcess>
            </ContainerText>
          </Box>
        </ContainerBoxe>

        <ContainerBoxe direction={'left'}>
          <Box>
            <ContainerCircle>
              <Circle color={'#2356CA'} >
                <Number>5</Number>
              </Circle>
            </ContainerCircle>
            <ContainerText>
              <TitleProcess>Rede da concessionária</TitleProcess>
              <TextProcess>A energia produzida dependerá da hora do dia e das condições climáticas. Logo, caso a energia gerada seja maior que o consumo imediato, ela é injetada na rede da concessionária.</TextProcess>
            </ContainerText>
          </Box>
        </ContainerBoxe>

        <ContainerBoxe margin={true} direction={'right'}>
          <Box>
            <ContainerCircle>
              <Circle color={'#F06C2B'} >
                <Number>6</Number>
              </Circle>
            </ContainerCircle>
            <ContainerText>
              <TitleProcess>Acumulação de créditos</TitleProcess>
              <TextProcess>Com isso, você não precisará armazenar a energia gerada em bateria, e, no final do mês, se houver esse excesso, você acumula créditos de energia que podem ser abatidos das suas próximas faturas.</TextProcess>
            </ContainerText>
          </Box>
        </ContainerBoxe>
      </ContainerProcess>
    </>
  )
}

export default AboutEnergyPage