import styled from 'styled-components'

import background from "../../assets/images/image9.jpeg"

import { device } from '../../styles/Devices';


export const Container = styled.div`
  width: 100% ;
  display: flex ;
  flex-direction: row;
  padding: ${props => props.theme.spaces.page}px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background: #EFEFEF ;

  @media ${device.laptop}{
    padding: ${props => props.theme.spaces.pageMobile}px;
    flex-direction: column;
    padding-top: 50px;
    text-align: center;
  }
`

export const ContainerShapes = styled.div`
display: flex ;
 flex: 1;
  align-items: center;
  justify-content: flex-end;
`

export const ShapeOne = styled.div`
  margin-top: -210px;
  width: 450px;
  height: 300px;
  margin-right: -40px;
  background: ${props => props.theme.colors.button} ;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;

  @media ${device.laptop}{
    margin: 50px -25px 100px 0;
    width: 225px;
    height: 150px;
  }
`

export const ShapeTwo = styled.div`
   width: 450px;
  height: 300px;
  background-image: url(${background});
  background-size: cover;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  position: absolute;
  margin: 0px 0 125px 70px;

  @media ${device.laptop}{
    margin: 50px 0 50px 0;
    width: 225px;
    height: 150px;
  }
`

export const ContainerInformation = styled.div`
display: flex ;
flex:1;
flex-direction: column;

`

export const Title = styled.p`
  margin: 0 ;
  color: ${props => props.theme.colors.secondary};
  font-size: 40px;
  font-weight: bold;
  @media ${device.laptop}{
    font-size: 20px;
  }
`

export const Description = styled.p`
  font-size: 20px;
  margin: 0;
  font-weight: 200;
  margin-top: 16px;
  @media ${device.laptop}{
    font-size: 12px;
  }
`