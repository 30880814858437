import styled from 'styled-components'

import { CgChevronRight, CgChevronLeft } from "react-icons/cg"


import { device } from '../../styles/Devices';


export const Container = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.background};
  padding: 30px ${props => props.theme.spaces.page}px;
  border-top-left-radius: 50px;
  height: 550px;
  border-bottom-right-radius: 50px;
  box-sizing: border-box;

  @media ${device.laptop} {
    padding: 32px;
    height: 220px;
  }
`


export const Title = styled.p`
  font-size: 50px;
  margin: 0;
  color: ${props => props.theme.colors.light};
  width: 70%;
  font-weight: bold;
  @media ${device.laptop} {
    font-size: 25px;
  }
`

export const SubTitle = styled.p`
  font-size: 30px;
  color: ${props => props.theme.colors.light};
  margin: 32px 0;
  width: 100%;

  @media ${device.laptop} {
    font-size: 15px;
  }
`

export const BoxImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-direction: row;
  margin-top: 16px;

  @media ${device.laptop} {

  }
`
export const Image = styled.img`
  width: 700px;
  max-width: 700px;
  min-width: 700px;
  height: 450px;
  max-height: 450px;
  min-height: 450px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  box-shadow: 0px 3px 22px -8px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 3px 22px -8px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 3px 22px -8px rgba(0,0,0,0.75);

  @media ${device.laptop} {
    width: 260px;
    max-width: 260px;
    min-width: 260px;
    height: 170px;
    max-height: 170px;
    min-height: 170px;
  }
`

export const ChevronLeft = styled(CgChevronLeft)`
  cursor: pointer;
  opacity: ${props => props.disable ? 0.4 : 1};
`

export const ChevronRight = styled(CgChevronRight)`
  cursor: pointer;
  opacity: ${props => props.disable ? 0.4 : 1}
`