import React, { useRef } from "react"

import About from "../../components/About/About"
import BannerPhoto from "../../components/BannerPhoto/BannerPhoto"
import AboutEnergy from "../../components/AboutEnergy/AboutEnergy"
import Advantages from "../../components/Advantages/Advantages"
import Projects from "../../components/Projects/Projects"

import {
  ContainerMain,
  DiscoveryDivisor,
  DiscoveryText,
  ContainerTriangle,
  Triangle,
} from "./HomeStyles"

const Home = () => {
  const aboutUs = useRef(null)
  const aboutEnergy = useRef(null)
  const projects = useRef(null)

  return (
    <ContainerMain>
      <ContainerTriangle>
        <Triangle />
      </ContainerTriangle>
      <DiscoveryDivisor>
        <DiscoveryText>Conheça Parcon Solar</DiscoveryText>
      </DiscoveryDivisor>
      <About ref={aboutUs} />
      <BannerPhoto />
      <AboutEnergy ref={aboutEnergy} />
      <Advantages />
      <Projects ref={projects} />
    </ContainerMain>
  )
}

export default Home
